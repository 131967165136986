<template>
  <div class="text-center q-pt-xs q-pb-sm full-height row justify-center">
    <h6 class="q-my-auto">
      {{ text }}
    </h6>
  </div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    text: {
      type: String,
      required: true,
      default () {
        return ''
      }
    }
  }
}
</script>
